import { gsap } from 'gsap';
import { $, $$, wrap } from './helper/utils';
import outline from './helper/outline';
import inView from './helper/inView';
import { DESKTOPMEDIA, MOBILMEDIA } from './helper/constants';

outline();

const sliderMap = new Map();
const sliderDragging = new Map();
let desktopNavLoaded = false;
let mobileNavLoaded = false;
let responsiveTableLoaded = false;

function loadDesktopNavigation() {
  import('./components/navigation.js')
    .then((navigation) => {
      navigation.default();
      desktopNavLoaded = true;
    })
    .catch((err) => {
      console.warn('Chunk navigation.js loading failed', err);
    });
}

function loadMobilenav() {
  if (mobileNavLoaded === true) {
    return;
  }

  import('./components/offcanvas')
    .then((offcanvas) => {
      const showMenuBtn = $('.show-menu');
      const navigation = offcanvas.default($('.mobile-nav'), 'left');
      showMenuBtn.addEventListener('click', navigation.open, { passive: true });
      mobileNavLoaded = true;

      const showSearch = $('#header .search button');
      const search = offcanvas.default($('.mobile-search-wrapper'));
      showSearch.addEventListener('click', () => {
        if (MOBILMEDIA.matches) {
          search.open();
        }
      }, { passive: true });
    })
    .catch((err) => {
      console.warn('Chunk offcanvas.js loading failed', err);
    });
}

export default function () {
  if (DESKTOPMEDIA.matches && desktopNavLoaded === false) {
    loadDesktopNavigation();
  } else if (MOBILMEDIA.matches) {
    if (mobileNavLoaded === false) loadMobilenav();
  }

  DESKTOPMEDIA.addListener((e) => {
    if (e.matches) {
      if (desktopNavLoaded === false) loadDesktopNavigation();
      return;
    }

    if (mobileNavLoaded === false) loadMobilenav();
  });

  import('./components/cart.js')
    .then((cart) => cart.default())
    .catch((err) => {
      console.warn('Chunk cart.js loading failed', err);
    });

  import('./components/search.js')
    .then((search) => search.default())
    .catch((err) => {
      console.warn('Chunk search.js loading failed', err);
    });

  import('./components/header.js')
    .then((header) => header.default())
    .catch((err) => {
      console.warn('Chunk header.js loading failed', err);
    });

  if ($('.form-group > .form-line') !== null) {
    import('./components/form.js')
      .then((form) => form.default())
      .catch((err) => {
        console.warn('Chunk form.js loading failed', err);
      });
  }

  if ($('.read-more') !== null) {
    import('./components/readMore.js')
      .then((readMore) => readMore.default())
      .catch((err) => {
        console.warn('Chunk readMore.js loading failed', err);
      });
  }

  if ($('.siema') !== null) {
    import('./components/slider.js')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.consultantFilter') !== null) {
    import('./components/consultantFilter.js')
      .then((consultantFilter) => consultantFilter.default())
      .catch((err) => {
        console.warn('Chunk consultantFilter.js loading failed', err);
      });
  }

  const loadResponsiveTable = () => {
    const table = $$('main table');
    if (table.length > 0) {
      for (let i = 0; i < table.length; i++) {
        const el = table[i];
        const wrapper = document.createElement('div');
        wrapper.classList.add('responsiveTable');

        wrap(el, wrapper);
      }
    }

    responsiveTableLoaded = true;
  };

  if ($('.open-modal') !== null) {
    import('./components/modal.js')
      .then((modal) => {
        const modals = $$('.open-modal');

        for (let i = 0; i < modals.length; i++) {
          const el = modals[i];
          const Modal = modal.default(el);
          el.addEventListener('click', Modal.show);
        }
      })
      .catch((err) => {
        console.warn('Chunk modal.js loading failed', err);
      });
  }

  const lightboxes = $$('.lightbox');
  inView(lightboxes, () => {
    import('./components/lightbox.js')
      .then((lightbox) => lightbox.default('.lightbox', sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk lightbox.js loading failed', err);
      });
  }, true);

  const pwChanged = $('.passwort-changed');
  if (pwChanged !== null && pwChanged.offsetWidth > 0) {
    gsap.to(pwChanged, {
      duration: 0.275,
      delay: 2,
      opacity: 0,
      ease: 'sine.out',
    }).then(() => {
      pwChanged.style.display = 'none';
    });
  }

  const bsElements = $$('.nav-tabs, [data-toggle="collapse"]');
  inView(bsElements, () => {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, { duration: 0.3, opacity: 0 }, { opacity: 1, ease: 'linear' });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }
        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }, true);

  MOBILMEDIA.addListener((e) => {
    if (e.matches) {
      if (responsiveTableLoaded === false) loadResponsiveTable();
    }
  });

  if (MOBILMEDIA.matches) {
    loadResponsiveTable();
  }

  const findConsultant = $('.consultant-finder');
  if (findConsultant != null) {
    const consultantContent = findConsultant.lastElementChild;
    const closeBtn = consultantContent.querySelector('.close-consultant-finder');

    findConsultant.firstElementChild.addEventListener('click', () => {
      gsap
        .to(consultantContent, {
          duration: 0.325,
          scale: 1,
          autoAlpha: 1,
          ease: 'sine.out',
        })
        .then(() => {
          closeBtn.focus();
        });
    });

    findConsultant.firstElementChild.addEventListener('mouseenter', () => {
      gsap
        .to(consultantContent, {
          duration: 0.325,
          scale: 1,
          autoAlpha: 1,
          ease: 'sine.out',
        });
    });

    closeBtn.addEventListener('click', () => {
      gsap.to(consultantContent, {
        duration: 0.25,
        scale: 0,
        autoAlpha: 0,
        ease: 'sine.in',
      });
    });

    // close on click outside
    document.addEventListener('click', (e) => {
      if (e.target === findConsultant || findConsultant.contains(e.target) === false) {
        gsap.to(consultantContent, {
          duration: 0.25,
          scale: 0,
          autoAlpha: 0,
          ease: 'sine.in',
        });
      }
    });
  }
}
